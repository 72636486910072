<template>
  <div >
    <h1>Back office ChaYall</h1>
    <el-card class="box-card">
      Oops! We can't find what you are looking for
    </el-card>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Error500 extends Vue {}
</script>
